<template>
  <a-spin :spinning="isLoading">
    <div>
      <a-row :gutter="24">
        <a-col :span="8" class="fixed-side-container">
          <div class="product-steps-container">
            <a-steps v-model="currentStep" direction="vertical" size="small">
              <a-step
                title="Informasi Produk"
                description="Lengkapi informasi produk yang akan ditambahkan"
              />
              <a-step
                title="Varian Produk"
                description="Lengkapi varian produk yang akan ditambahkan"
                :disabled="formStep0"
              />
              <a-step
                title="Detail Produk"
                description="Lengkapi detail produk yang akan ditambahkan"
                :disabled="formStep0 || formStep1"
              />
              <a-step
                title="Ringkasan Produk"
                description="Periksa kembali barang yang akan ditambahkan"
                :disabled="formStep0 || formStep1 || formStep2"
              />
            </a-steps>
          </div>
        </a-col>
        <template v-if="currentStep === 0">
          <a-col :span="16">
            <div class="product-form-container">
              <a-form-model
                hideRequiredMark
                layout="vertical"
                ref="productForm"
                :model="productData"
              >
                <div style="position: relative">
                  <span
                    style="position: absolute; right: 4px; top: -5px;
                    color: #f5222d; font-size: 13px;"
                    v-show="isProdNameExist"
                  >
                    Nama Produk sudah pernah ada
                  </span>
                  <a-form-model-item
                    label="Nama Produk"
                    prop="name"
                    :rules="[
                      {
                        required: true,
                        message: 'Data tidak valid',
                        trigger: 'change',
                      },
                      {
                        max: 100,
                        message: 'Nama Produk melebihi 100 karakter',
                        trigger: 'change',
                      },
                    ]"
                  >
                    <a-auto-complete
                      v-model="productData.name"
                      :open="openProdName"
                      @search="searchProdName"
                      @select="prodNameOnSelect"
                    >
                      <template slot="dataSource">
                        <a-select-option v-for="prd in productNameSearchData" :key="prd.id">
                          {{ prd.name }}
                        </a-select-option>
                      </template>
                      <a-input
                        size="large"
                        placeholder="Misal: Panadol"
                        :style="isProdNameExist ? 'color: red;' : 'color: rgba(0, 0, 0, 0.65);'"
                        @change="checkFormValid(0)"
                      >
                        <a-icon slot="suffix" type="loading" v-if="loadingProdName" />
                      </a-input>
                    </a-auto-complete>
                  </a-form-model-item>
                </div>
                <a-form-model-item>
                  <div class="product-button-container">
                    <a-button size="large" class="product-button-cancel" @click="handleCancel">
                      Batal
                    </a-button>
                    <a-button
                      size="large"
                      type="primary"
                      class="product-button-next"
                      html-type="submit"
                      :disabled="formStep0"
                      @click="changeStep(0)"
                    >
                      Selanjutnya
                    </a-button>
                  </div>
                </a-form-model-item>
              </a-form-model>
            </div>
          </a-col>
        </template>
        <template v-else-if="currentStep === 1">
          <a-col :span="16">
            <div class="product-form-container">
              <div class="product-name-title">{{ this.productData.name }}</div>
              <a-form-model
                hideRequiredMark
                layout="vertical"
                ref="variantForm"
                :model="variantsForm"
              >
                <div class="variants-wrapper" ref="variantWrapper">
                  <div
                    v-for="(variant, index) in variantsForm.variantsData"
                    :key="variant.key"
                    class="variant-container"
                  >
                    <a-form-model-item
                      label="Nama Varian"
                      :prop="'variantsData.' + index + '.name'"
                      :rules="[
                        {
                          required: true,
                          message: 'Data tidak valid',
                          trigger: 'change'
                        },
                        {
                          max: 100,
                          message: 'Nama Varian melebihi 100 karakter',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <a-input
                        v-model="variant.name"
                        size="large"
                        placeholder="Misal: Panadol Biru"
                        @change="checkFormValid(1)"
                      />
                    </a-form-model-item>
                    <a-form-model-item
                      label="SKU"
                      :prop="'variantsData.' + index + '.sku'"
                      :rules="[
                        {
                          required: true,
                          message: 'Data tidak valid',
                          trigger: 'change',
                        },
                        {
                          max: 100,
                          message: 'SKU melebihi 100 karakter',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <a-input
                        v-model="variant.sku"
                        size="large"
                        placeholder="Misal: PCTPD5001104"
                        @change="checkFormValid(1)"
                      />
                    </a-form-model-item>
                    <a-form-model-item
                      label="Deskripsi"
                      :prop="'variantsData.' + index + '.description'"
                      :rules="[
                        {
                          required: true,
                          message: 'Data tidak valid',
                          trigger: 'change'
                        },
                        {
                          max: 1000,
                          message: 'Deskripsi melebihi limit 1000 karakter',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <a-textarea
                        v-model="variant.description"
                        :auto-size="{ minRows: 3, maxRows: 6 }"
                        placeholder="Tuliskan deskripsi singkat tentang varian produk"
                        @change="checkFormValid(1)"
                      />
                    </a-form-model-item>
                    <a-form-model-item
                      label="Referensi Produk"
                      :prop="'variantsData.' + index + '.medicine_reference_id'"
                      :rules="[
                        {
                          required: true,
                          message: 'Referensi obat tidak ditemukan',
                          trigger: 'blur'
                        }
                      ]"
                    >
                      <a-select
                        show-search
                        v-model="variant.medicine_reference_name"
                        :value="variant.medicine_reference_name"
                        size="large"
                        placeholder="Misal: Paracetamol 500 MG"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        :loading="loadingRefProd"
                        @search="handleMedicineRefSearch($event)"
                        @change="handleMedicineRefChange($event, index)"
                      >
                        <a-select-option
                          v-for="d in medicineRefData"
                          :key="d.id"
                          :value="d.brand"
                        >
                          {{ d.brand }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="Foto Produk">
                      <div style="display: flex;">
                        <div class="margin-smallR">
                          <img
                            v-if="variant.photos.length > 0 && variant.photos[0].variant_photo"
                            :style="{
                              backgroundImage: `url(${variant.photos[0].variant_photo})`,
                              borderRadius: '16px',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center'
                            }"
                            width="80"
                            height="80"
                          />
                          <div v-else :style="imgPreviewStyle">
                            <a-icon style="font-size: 32px; color: #b2b3b5" type="picture" />
                          </div>
                        </div>
                        <div
                          style="display: flex;
                          flex-direction: column;
                          align-items: flex-start;"
                        >
                          <input
                            type="file"
                            ref="file"
                            style="display: none"
                            accept="image/jpeg, image/png"
                            @change="previewFiles"
                          />
                          <div style="display: flex">
                            <a-button
                              type="secondary"
                              class="button-download margin-microB"
                              @click="handleReadOnly($event, index)"
                              ref="fileText"
                            >
                              Unggah Foto
                            </a-button>
                            <span
                              v-if="imgError"
                              style="margin: 6px 0 0 8px; color: red;"
                            >
                              Ukuran atau dimensi foto salah
                            </span>
                          </div>
                          <span class="p-text-italic font-14" style="color: #b2b3b5">
                            Format foto berbentuk jpg/jpeg dan berukuran
                            <br />maksimal 2MB
                          </span>
                        </div>
                      </div>
                    </a-form-model-item>
                  </div>
                </div>
                <a-form-model-item v-show="!id">
                  <a-button
                    type="dashed"
                    style="width: 100%;"
                    size="large"
                    @click="addVariantData()"
                  >
                    <a-icon type="plus" /> Tambah Varian
                  </a-button>
                </a-form-model-item>
                <a-form-model-item>
                  <div class="variant-button-container">
                    <a-button size="large" class="variant-button-prev" @click="changePrevStep(1)">
                      Kembali
                    </a-button>
                    <a-button
                      size="large"
                      type="primary"
                      class="variant-button-next"
                      :disabled="formStep1"
                      @click="changeStep(1)"
                    >
                      Selanjutnya
                    </a-button>
                  </div>
                </a-form-model-item>
              </a-form-model>
            </div>
          </a-col>
        </template>
        <template v-else-if="currentStep === 2">
          <a-col :span="16">
            <div class="product-form-container">
              <div class="product-name-title">{{ this.productData.name }}</div>
              <a-form-model
                hideRequiredMark
                layout="vertical"
                ref="attributeForm"
                :model="attributeForm"
              >
                <div class="attributes-wrapper">
                  <div
                    v-for="(attribute, index) in attributeForm.attributeData"
                    :key="attribute.key"
                    class="attribute-container"
                  >
                    <a-collapse
                      expand-icon-position="right"
                      default-active-key="1"
                      class="p-product-attribute-collapse"
                    >
                      <template #expandIcon="props">
                        <a-icon
                          class="sidebar-menu-icon"
                          type="up"
                          :rotate="props.isActive ? 0 : 180"
                        />
                      </template>
                      <a-collapse-panel
                        :key="`${index + 1}`"
                        :header="variantsForm.variantsData[index].name"
                      >
                        <a-form-model-item
                          label="Satuan"
                          :prop="'attributeData.' + index + '.unit'"
                          :rules="{
                            required: true,
                            message: 'Data tidak valid',
                            trigger: 'change'
                          }"
                        >
                          <a-select
                            v-model="attributeForm.attributeData[index].unit"
                            size="large"
                            placeholder="Pilih Satuan"
                            @change="checkFormValid(2)"
                          >
                            <a-select-option
                              v-for="unit in attrUnitSelect"
                              :value="unit"
                              :key="unit"
                            >
                              {{ unit }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                        <div style="display: flex">
                          <a-form-model-item
                            label="Isi"
                            :prop="'attributeData.' + index + '.amount'"
                            :rules="{
                              required: true,
                              message: 'Data tidak valid',
                              trigger: 'change'
                            }"
                          >
                            <a-input
                              v-model="attributeForm.attributeData[index].amount"
                              size="large"
                              placeholder="Misal: 500"
                              style="width: 100px; margin-right: 8px"
                              @change="checkFormValid(2)"
                              @keypress="isNumber($event)"
                            />
                          </a-form-model-item>
                          <a-form-model-item
                            :prop="'attributeData.' + index + '.amountUnit'"
                            :rules="{
                              required: true,
                              message: 'Data tidak valid',
                              trigger: 'change'
                            }"
                          >
                            <a-select
                              v-model="attributeForm.attributeData[index].amountUnit"
                              size="large"
                              style="width: 100px; margin-top: 22px"
                              placeholder="Pilih Dosis"
                              @change="checkFormValid(2)"
                            >
                              <a-select-option
                                v-for="amountUnit in attrAmountUnitSelect"
                                :value="amountUnit"
                                :key="amountUnit"
                              >
                                {{ amountUnit }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </div>
                        <a-form-model-item
                          label="Paten"
                          :prop="'attributeData.' + index + '.patent'"
                          :rules="{
                            required: true,
                            message: 'Data tidak valid',
                            trigger: 'change'
                          }"
                        >
                          <a-select
                            v-model="attributeForm.attributeData[index].patent"
                            size="large"
                            placeholder="Pilih Paten"
                            @change="checkFormValid(2)"
                          >
                            <a-select-option
                              v-for="patent in attrPatentSelect"
                              :value="patent"
                              :key="patent"
                            >
                              {{ patent }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
                <a-form-model-item>
                  <div class="variant-button-container">
                    <a-button size="large" class="variant-button-prev" @click="changePrevStep(2)">
                      Kembali
                    </a-button>
                    <a-button
                      size="large"
                      type="primary"
                      class="variant-button-next"
                      :disabled="formStep2"
                      @click="changeStep(2)"
                    >
                      Selanjutnya
                    </a-button>
                  </div>
                </a-form-model-item>
              </a-form-model>
            </div>
          </a-col>
        </template>
        <template v-else-if="currentStep === 3">
          <a-col :span="16">
            <div class="product-form-container">
              <div class="product-name-title">{{ this.productData.name }}</div>
              <div class="ringkasan-wrapper">
                <div
                  v-for="(variant, ix) in variantsForm.variantsData"
                  :key="variant.key"
                  class="ringkasan-container"
                >
                  <a-collapse
                    expand-icon-position="right"
                    default-active-key="1"
                    class="p-product-ringkasan-collapse"
                  >
                    <template #expandIcon="props">
                      <a-icon
                        class="sidebar-menu-icon"
                        type="up"
                        :rotate="props.isActive ? 0 : 180"
                      />
                    </template>
                    <a-collapse-panel key="1" :header="variantsForm.variantsData[ix].name">
                      <div style="display: flex">
                        <div>
                          <img
                            v-if="variant.photos.length > 0 && variant.photos[0].variant_photo"
                            :style="{
                              backgroundImage: `url(${variant.photos[0].variant_photo})`,
                              borderRadius: '16px',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center'
                            }"
                            width="80"
                            height="80"
                          />
                          <div v-else :style="imgPreviewStyle">
                            <a-icon style="font-size: 32px; color: #b2b3b5" type="picture" />
                          </div>
                        </div>
                        <div style="margin-left: 16px">
                          <div style="font-size: 18px; font-weight: 700; color: #0146ab;">
                            {{ variantsForm.variantsData[ix].name }}
                          </div>
                          <div
                            style="font-size: 16px; font-weight: normal;
                            color: #7f8184; margin-top: 4px;"
                          >
                            {{ variantsForm.variantsData[ix].sku }}
                          </div>
                        </div>
                      </div>
                      <a-divider style="margin: 18px 0;" />
                      <div>
                        <div class="label">Deskripsi</div>
                        <div class="sub-label">{{ variantsForm.variantsData[ix].description }}</div>
                      </div>
                      <a-divider style="margin: 18px 0;" />
                      <div>
                        <div class="label">Referensi Produk</div>
                        <div class="sub-label">
                          {{ variantsForm.variantsData[ix].medicine_reference_name }}
                        </div>
                      </div>
                      <a-divider style="margin: 18px 0;" />
                      <div style="display: flex">
                        <div style="margin-right: 40px">
                          <div class="label">Satuan</div>
                          <div class="sub-label">{{ attributeForm.attributeData[ix].unit }}</div>
                        </div>
                        <div style="padding-left: 40px; border-left: solid 2px #e5e5e6;">
                          <div class="label">Isi</div>
                          <div class="sub-label">
                            {{ attributeForm.attributeData[ix].amount }}
                            {{ attributeForm.attributeData[ix].amountUnit }}
                          </div>
                        </div>
                      </div>
                      <a-divider style="margin: 18px 0;" />
                      <div>
                        <div class="label">Paten</div>
                        <div class="sub-label">{{ attributeForm.attributeData[ix].patent }}</div>
                      </div>
                    </a-collapse-panel>
                  </a-collapse>
                </div>
              </div>
              <div class="variant-button-container">
                <a-button size="large" class="variant-button-prev" @click="changePrevStep(3)">
                  Kembali
                </a-button>
                <a-button
                  size="large"
                  type="primary"
                  class="variant-button-next"
                  :loading="isLoadingUpdate"
                  @click="handleSave"
                >
                  {{ buttonSubmitTitle }}
                </a-button>
              </div>
            </div>
          </a-col>
        </template>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { message } from "ant-design-vue";

export default Vue.extend({
  data() {
    return {
      formLayout: "vertical",
      productData: {
        name: "",
      },
      variantsForm: {
        variantsData: [],
      },
      attributeForm: {
        attributeData: [],
      },
      productDataUnmodif: {
        name: "",
      },
      variantsFormUnmodif: {
        variantsData: [],
      },
      attributeFormUnmodif: {
        attributeData: [],
      },
      formStep0: true,
      formStep1: true,
      formStep2: true,
      currentStep: 0,
      imgPreviewStyle: {
        backgroundColor: "#e5e5e6",
        borderRadius: "16px",
        width: "80px",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      imgError: false,
      imgCurrIdx: null,
      isLoading: false,
      isLoadingUpdate: false,
      attrUnitId: "",
      attrPatentId: "",
      attrAmountUnitId: "",
      attrAmountId: "",
      attrUnitSelect: [],
      attrPatentSelect: [],
      attrAmountUnitSelect: [],
      medicineRefData: [],
      productNameSearchData: [],
      buttonSubmitTitle: "Tambah Produk",
      isProdNameExist: false,
      timeout: null,
      tmeout: null,
      currentValue: "",
      loadingRefProd: false,
      loadingProdName: false,
      openProdName: false,
    };
  },
  props: {
    id: { type: String },
    clientId: { type: String },
    variantName: { type: String },
    productId: { type: String },
    recordProductId: { type: String },
    recordProductName: { type: String },
  },
  methods: {
    ...mapActions("ProductStore", [
      "createProduct",
      "createVariant",
      "saveAttributesToVariant",
      "updateProduct",
      "fetchAtributes",
      "fetchVariant",
      "fetchProduct",
      "fetchProductsAndProductByName",
      "saveVariantPhotos",
      "updateProductVariantAttribute",
      "updateProductVariantAttributePhoto",
      "updateProductVariantAttributePhotoWithDelete",
    ]),
    ...mapActions("MedicineStore", ["fetchMedicines"]),
    isNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
      return true;
    },
    medRefSearchResult(data) {
      this.medicineRefData = data;
      this.checkFormValid(1);
    },
    handleMedicineRefSearch(value) {
      this.fetchMedicineRef(value);
    },
    handleMedicineRefChange(value, index) {
      const medicineReference = this.medicineRefData.filter((medicine) => medicine.brand === value);
      this.variantsForm.variantsData[index].medicine_reference_id = medicineReference[0].id;
      this.checkFormValid(1);
    },
    fetchMedicineRef(value) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.currentValue = value;

      this.timeout = setTimeout(() => {
        this.loadingRefProd = true;
        this.fetchMedicines({
          limit: 100,
          offset: 0,
          search: `&brand=${value}`,
        })
          .then((d) => {
            this.loadingRefProd = false;
            if (this.currentValue === value) {
              const result = d.data;
              const data = [];
              result.forEach((r) => {
                data.push({
                  id: r.id,
                  brand: r.brand,
                });
              });
              this.medRefSearchResult(data);
            }
          })
          .catch((error) => {
            this.loadingRefProd = false;
            console.log("error fetch med: ", error);
          });
      }, 800);
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    prodNameOnSelect(value) {
      this.openProdName = false;
      const recordProdName = String(this.recordProductName).trim();
      const selectedValue = String(value).trim();
      if (this.recordProductName && recordProdName === selectedValue) {
        this.formStep0 = false;
        this.isProdNameExist = false;
      } else {
        this.formStep0 = true;
        this.isProdNameExist = true;
      }
    },
    searchProdName(value) {
      if (value.length > 100) {
        this.formStep0 = true;
        return;
      }
      this.productNameSearchData = [];
      this.openProdName = false;
      this.isProdNameExist = false;

      if (this.tmeout) {
        clearTimeout(this.tmeout);
      }

      const recordProdName = String(this.recordProductName).trim();
      const val = String(value).trim();

      if (value === "" || !value) {
        this.formStep0 = true;
      } else if (this.recordProductName && recordProdName === val) {
        // value input same as first fetch product name
        this.formStep0 = false;
        this.isProdNameExist = false;
      } else {
        this.formStep0 = true;
        const options = {
          productName: value,
          first: 100,
          page: 1,
        };

        this.tmeout = setTimeout(() => {
          this.loadingProdName = true;
          this.fetchProductsAndProductByName(options)
            .then((res) => {
              this.loadingProdName = false;
              if (res.products.paginatorInfo.total === 0) {
                this.productNameSearchData = [];
                this.formStep0 = false;
                this.isProdNameExist = false;
              } else {
                this.productNameSearchData = res.products.data;

                const typedProdName = String(this.productData.name).trim();
                if (this.recordProductName && recordProdName === typedProdName) {
                  this.productNameSearchData = [];
                  this.formStep0 = false;
                  this.isProdNameExist = false;
                } else if (
                  res && res.products && res.products.data && res.products.data.length > 0
                ) {
                  this.formStep0 = true;

                  const isExists = res.products.data.some((prd) => String(prd.name).trim() === typedProdName); //eslint-disable-line

                  if (isExists) {
                    this.isProdNameExist = true;
                    this.openProdName = true;
                  } else if (res && res.productByName && res.productByName.name) {
                    this.formStep0 = true;
                    this.isProdNameExist = true;
                    this.openProdName = true;
                  } else {
                    this.formStep0 = false;
                    this.isProdNameExist = false;
                  }
                } else {
                  this.formStep0 = false;
                  this.isProdNameExist = false;
                }
              }
            })
            .catch((error) => {
              this.loadingProdName = false;
              this.formStep0 = true;
              // this.isProdNameExist = true;
              console.log("error fetching products name: ", error);
            });
        }, 800);
      }
    },
    checkFormValid(step) {
      /* eslint-disable */
      if (step === 0) {
        if (this.productData.name === "" || this.productData.name.length > 100) {
          this.formStep0 = true;
        } else {
          this.formStep0 = false;
        }
      } else if (step === 1) {
        this.formStep1 = this.variantsForm.variantsData.some(variant => {
          return (
            variant.name === "" ||
            variant.name.length > 100 ||
            variant.sku === "" ||
            variant.sku.length > 100 ||
            variant.description === "" ||
            variant.description.length > 1000 ||
            variant.medicine_reference_id === "" ||
            !variant.medicine_reference_id
          );
        });
      } else if (step === 2) {
        this.formStep2 = this.attributeForm.attributeData.some(attr => {
          return (
            attr.unit === "" || !attr.unit || attr.amount === "" || attr.amountUnit === "" || !attr.amountUnit || attr.patent === "" || !attr.patent
          );
        });
      }
      /* eslint-enable */
    },
    changePrevStep(current) {
      if (current === 1) {
        this.currentStep = 0;
      } else if (current === 2) {
        this.currentStep = 1;
      } else if (current === 3) {
        this.currentStep = 2;
      }
    },
    changeStep(current) {
      if (current === 0) {
        this.checkFormValid(0);
        this.currentStep = 1;
      } else if (current === 1) {
        this.checkFormValid(1);
        this.currentStep = 2;
      } else if (current === 2) {
        this.checkFormValid(2);
        this.currentStep = 3;
      }
    },
    handleOkMessage(msg) {
      this.isLoadingUpdate = false;
      message.success(msg);
      this.$emit("handleOk");
    },
    handleSave(e) {
      e.preventDefault();
      this.isLoadingUpdate = true;
      if (this.id || this.recordProductId) {
        this.variantsForm.variantsData.forEach((variant, idx) => {
          if (variant.id) {
            // update varian

            let isThereChanges = false;

            if (this.productDataUnmodif.name.trim() !== this.productData.name.trim()) {
              console.log("changes");
              isThereChanges = true;
            }

            /* eslint-disable */
            if (
              isThereChanges === false
              && (this.variantsFormUnmodif.variantsData[idx].name.trim() !== variant.name.trim()
              || this.variantsFormUnmodif.variantsData[idx].sku.trim() !== variant.sku.trim()
              || this.variantsFormUnmodif.variantsData[idx].description.trim() !== variant.description.trim()
              || this.variantsFormUnmodif.variantsData[idx].medicine_reference_id.trim() !== variant.medicine_reference_id.trim()
              || (variant.photos.length > 0 && variant.photos[0].fileImage))
            ) {
              isThereChanges = true;
            }
            if (
              isThereChanges === false
              && (this.attributeFormUnmodif.attributeData[idx].unit.trim() !== this.attributeForm.attributeData[idx].unit.trim()
              || this.attributeFormUnmodif.attributeData[idx].amount.trim() !== this.attributeForm.attributeData[idx].amount.trim()
              || this.attributeFormUnmodif.attributeData[idx].amountUnit.trim() !== this.attributeForm.attributeData[idx].amountUnit.trim()
              || this.attributeFormUnmodif.attributeData[idx].patent.trim() !== this.attributeForm.attributeData[idx].patent.trim())
            ) {
              isThereChanges = true;
            }
            /* eslint-enable */

            if (isThereChanges) {
              const payld = {
                product_id: this.productData.id,
                input_product: { name: this.productData.name },
              };
              payld.variant_id = variant.id;
              payld.input_varaint = {
                product_id: this.productData.id,
                name: variant.name,
                sku: variant.sku,
                description: variant.description,
                medicine_reference_id: variant.medicine_reference_id,
              };
              payld.attr_variant_id = variant.id;
              payld.attributes = [
                {
                  attribute_id: this.attrUnitId,
                  value: this.attributeForm.attributeData[idx].unit,
                },
                {
                  attribute_id: this.attrAmountId,
                  value: this.attributeForm.attributeData[idx].amount,
                },
                {
                  attribute_id: this.attrAmountUnitId,
                  value: this.attributeForm.attributeData[idx].amountUnit,
                },
                {
                  attribute_id: this.attrPatentId,
                  value: this.attributeForm.attributeData[idx].patent,
                },
              ];

              if (variant.photos.length === 0) {
                this.updateProductVariantAttribute(payld)
                  .then(() => {
                    if (idx === this.variantsForm.variantsData.length - 1) {
                      const msg = this.id ? "Data varian berhasil diubah" : "Data varian berhasil ditambah";
                      this.handleOkMessage(msg);
                    }
                  })
                  .catch((error) => {
                    console.log("error update variant: ", error);
                    message.error(`Gagal merubah data varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                    if (idx === this.variantsForm.variantsData.length - 1) {
                      this.isLoadingUpdate = false;
                    }
                  });
              } else if (variant.photos[0].fileImage) {
                if (variant.photos[0].id) {
                  payld.photo_id = variant.photos[0].id;
                  payld.photo_variant_id = variant.id;
                  payld.photos = [variant.photos[0].fileImage];
                  this.updateProductVariantAttributePhotoWithDelete(payld)
                    .then(() => {
                      if (idx === this.variantsForm.variantsData.length - 1) {
                        const msg = this.id ? "Data varian berhasil diubah" : "Data varian berhasil ditambah";
                        this.handleOkMessage(msg);
                      }
                    })
                    .catch((error) => {
                      console.log("error update variant: ", error);
                      message.error(`Gagal merubah data varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                      if (idx === this.variantsForm.variantsData.length - 1) {
                        this.isLoadingUpdate = false;
                      }
                    });
                } else {
                  payld.photo_variant_id = variant.id;
                  payld.photos = [variant.photos[0].fileImage];
                  this.updateProductVariantAttributePhoto(payld)
                    .then(() => {
                      if (idx === this.variantsForm.variantsData.length - 1) {
                        const msg = this.id ? "Data varian berhasil diubah" : "Data varian berhasil ditambah";
                        this.handleOkMessage(msg);
                      }
                    })
                    .catch((error) => {
                      console.log("error update variant: ", error);
                      message.error(`Gagal merubah data varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                      if (idx === this.variantsForm.variantsData.length - 1) {
                        this.isLoadingUpdate = false;
                      }
                    });
                }
              } else {
                this.updateProductVariantAttribute(payld)
                  .then(() => {
                    if (idx === this.variantsForm.variantsData.length - 1) {
                      const msg = this.id ? "Data varian berhasil diubah" : "Data varian berhasil ditambah";
                      this.handleOkMessage(msg);
                    }
                  })
                  .catch((error) => {
                    console.log("error update variant: ", error);
                    message.error(`Gagal merubah data varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                    if (idx === this.variantsForm.variantsData.length - 1) {
                      this.isLoadingUpdate = false;
                    }
                  });
              }
            } else if (idx === this.variantsForm.variantsData.length - 1) {
              const msg = this.id ? "Data varian berhasil diubah" : "Data varian berhasil ditambah";
              this.handleOkMessage(msg);
            }
          } else {
            // tambah varian baru
            const productPayload = {
              id: this.productData.id,
              input: { name: this.productData.name },
            };
            this.updateProduct(productPayload)
              .then(() => {
                const payload = {
                  product_id: this.productData.id,
                  name: variant.name,
                  sku: variant.sku,
                  description: variant.description,
                  medicine_reference_id: variant.medicine_reference_id,
                };

                this.createVariant(payload)
                  .then((res) => {
                    const payloadAttribute = {
                      variant_id: res.createVariant.id,
                      attributes: [
                        {
                          attribute_id: this.attrUnitId,
                          value: this.attributeForm.attributeData[idx].unit,
                        },
                        {
                          attribute_id: this.attrAmountId,
                          value: this.attributeForm.attributeData[idx].amount,
                        },
                        {
                          attribute_id: this.attrAmountUnitId,
                          value: this.attributeForm.attributeData[idx].amountUnit,
                        },
                        {
                          attribute_id: this.attrPatentId,
                          value: this.attributeForm.attributeData[idx].patent,
                        },
                      ],
                    };

                    this.saveAttributesToVariant(payloadAttribute)
                      .then(() => {
                        const photos = [];
                        variant.photos.forEach((photo) => {
                          photos.push(photo.fileImage);
                        });
                        const payloadPhotos = {
                          variant_id: res.createVariant.id,
                          photos,
                        };

                        if (photos.length > 0) {
                          this.saveVariantPhotos(payloadPhotos)
                            .then(() => {
                              if (idx === this.variantsForm.variantsData.length - 1) {
                                const msg = this.id ? "Data varian berhasil diubah" : "Data varian berhasil ditambah";
                                this.handleOkMessage(msg);
                              }
                            })
                            .catch((error) => {
                              console.log("error save variant photos: ", error);
                              message.error(`Gagal menambahkan foto dari varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                              if (idx === this.variantsForm.variantsData.length - 1) {
                                this.isLoadingUpdate = false;
                              }
                            });
                        } else if (idx === this.variantsForm.variantsData.length - 1) {
                          const msg = this.id ? "Data varian berhasil diubah" : "Data varian berhasil ditambah";
                          this.handleOkMessage(msg);
                        }
                      })
                      .catch((error) => {
                        console.log("error save attribute: ", error);
                        message.error(`Gagal menambahkan atribut dari varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                        if (idx === this.variantsForm.variantsData.length - 1) {
                          this.isLoadingUpdate = false;
                        }
                      });
                  })
                  .catch((error) => {
                    console.log("error create variant: ", error);
                    message.error(`Gagal menambahkan varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                    if (idx === this.variantsForm.variantsData.length - 1) {
                      this.isLoadingUpdate = false;
                    }
                  });
              })
              .catch((error) => {
                console.log("error update variant: ", error);
                message.error(`Gagal menambahkan data varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                if (idx === this.variantsForm.variantsData.length - 1) {
                  this.isLoadingUpdate = false;
                }
              });
          }
        });
      } else {
        // tambah produk
        this.createProduct(this.productData)
          .then((product) => {
            const productId = product.createProduct.id;

            this.variantsForm.variantsData.forEach((variant, idx) => {
              const payload = {
                product_id: productId,
                name: variant.name,
                sku: variant.sku,
                description: variant.description,
                medicine_reference_id: variant.medicine_reference_id,
              };

              this.createVariant(payload)
                .then((res) => {
                  const payloadAttribute = {
                    variant_id: res.createVariant.id,
                    attributes: [
                      {
                        attribute_id: this.attrUnitId,
                        value: this.attributeForm.attributeData[idx].unit,
                      },
                      {
                        attribute_id: this.attrAmountId,
                        value: this.attributeForm.attributeData[idx].amount,
                      },
                      {
                        attribute_id: this.attrAmountUnitId,
                        value: this.attributeForm.attributeData[idx].amountUnit,
                      },
                      {
                        attribute_id: this.attrPatentId,
                        value: this.attributeForm.attributeData[idx].patent,
                      },
                    ],
                  };

                  this.saveAttributesToVariant(payloadAttribute)
                    .then(() => {
                      const photos = [];
                      variant.photos.forEach((photo) => {
                        photos.push(photo.fileImage);
                      });
                      const payloadPhotos = {
                        variant_id: res.createVariant.id,
                        photos,
                      };

                      if (photos.length > 0) {
                        this.saveVariantPhotos(payloadPhotos)
                          .then(() => {
                            if (idx === this.variantsForm.variantsData.length - 1) {
                              this.handleOkMessage("Produk berhasil ditambah");
                            }
                          })
                          .catch((error) => {
                            console.log("error save variant photos: ", error);
                            message.error(`Gagal menambahkan foto dari varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                          });
                      } else if (idx === this.variantsForm.variantsData.length - 1) {
                        this.handleOkMessage("Produk berhasil ditambah");
                      }
                    })
                    .catch((error) => {
                      console.log("error save attribute: ", error);
                      message.error(`Gagal menambahkan atribut dari varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                    });
                })
                .catch((error) => {
                  console.log("error create variant: ", error);
                  message.error(`Gagal menambahkan varian ${variant.name}, mohon ulangi beberapa saat lagi`);
                });
            });
          })
          .catch((error) => {
            console.log("error create product: ", error);
            this.isLoadingUpdate = false;
            message.error("Gagal menambahkan produk, mohon ulangi beberapa saat lagi");
          });
      }
    },
    addVariantData() {
      this.variantsForm.variantsData.push({
        name: "",
        sku: "",
        description: "",
        medicine_reference_id: undefined,
        medicine_reference_name: "",
        photos: [],
        key: Date.now(),
      });

      this.attributeForm.attributeData.push({
        unit: undefined,
        amount: "",
        amountUnit: undefined,
        patent: undefined,
      });

      setTimeout(() => {
        if (this.$refs.variantWrapper) {
          // this.$refs.variantWrapper.scrollTop = this.$refs.variantWrapper.scrollHeight;
          this.$refs.variantWrapper.scrollTo({ top: this.$refs.variantWrapper.scrollHeight, behavior: "smooth" });
        }
      }, 100);

      this.checkFormValid(1);
      this.checkFormValid(2);
    },
    previewFiles(event) {
      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event && event.target && event.target.files[0];
        const img = new Image();
        this.imgError = false;

        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          if (file.size > 2048000 || img.width > 1980 || img.height > 1080) {
            this.imgError = true;
          } else {
            this.imgError = false;
            this.variantsForm.variantsData[this.imgCurrIdx].uplImage = URL.createObjectURL(file);

            if (this.variantsForm.variantsData[this.imgCurrIdx].photos.length === 0) {
              this.variantsForm.variantsData[this.imgCurrIdx].photos.push({
                variant_photo: URL.createObjectURL(file),
                fileImage: file,
              });
            } else {
              this.variantsForm.variantsData[this.imgCurrIdx].photos[0]
                .variant_photo = URL.createObjectURL(file);
              this.variantsForm.variantsData[this.imgCurrIdx].photos[0]
                .fileImage = file;
            }
          }
        };
      }
    },
    handleReadOnly(event, index) {
      this.imgCurrIdx = index;
      this.$refs.fileText[0].$el.setAttribute("readonly", "readonly");
      this.$refs.file[0].click();
    },
    fillAttributes(attributes) {
      this.attrAmountId = attributes.find((el) => el.name === "amount").id;
      this.attrAmountUnitId = attributes.find((el) => el.name === "amount_unit").id;
      this.attrPatentId = attributes.find((el) => el.name === "patent").id;
      this.attrUnitId = attributes.find((el) => el.name === "unit").id;
      this.attrUnitSelect = attributes.find((el) => el.name === "unit").validation;
      this.attrPatentSelect = attributes.find((el) => el.name === "patent").validation;
      this.attrAmountUnitSelect = attributes.find((el) => el.name === "amount_unit").validation;
    },
    fetchDataVariant() {
      // ubah varian
      this.formStep2 = false;
      const options = {
        id: this.id,
      };
      this.fetchVariant(options)
        .then((variant) => {
          this.fillAttributes(variant.attributes.data);

          this.productDataUnmodif.id = variant.variant.product_id;
          this.productDataUnmodif.name = variant.variant.product.name;

          this.productData.id = variant.variant.product_id;
          this.productData.name = variant.variant.product.name;

          this.medicineRefData.push({
            id: variant.variant.medicine_reference_id,
            brand: variant.variant.medicine_reference_name ? variant.variant.medicine_reference_name : "",
          });

          const ph = [];
          if (variant.variant.photos.length > 0) {
            ph.push({
              id: variant.variant.photos[0].id,
              variant_photo: variant.variant.photos[0].variant_photo,
            });
          }

          this.variantsFormUnmodif.variantsData.push({
            id: variant.variant.id,
            name: variant.variant.name,
            sku: variant.variant.sku,
            description: variant.variant.description,
            medicine_reference_id: variant.variant.medicine_reference_id,
            medicine_reference_name: variant.variant.medicine_reference_name,
            photos: ph,
            key: variant.id,
          });

          this.variantsForm.variantsData.push({
            id: variant.variant.id,
            name: variant.variant.name,
            sku: variant.variant.sku,
            description: variant.variant.description,
            medicine_reference_id: variant.variant.medicine_reference_id,
            medicine_reference_name: variant.variant.medicine_reference_name,
            photos: ph,
            key: variant.id,
          });

          if (variant.variant.attributes.length > 0) {
            this.attributeFormUnmodif.attributeData.push({
              unitId: variant.variant.attributes.filter((el) => el.name === "unit")[0].id,
              unit: variant.variant.attributes.filter((el) => el.name === "unit")[0].pivot.value,
              amountId: variant.variant.attributes.filter((el) => el.name === "amount")[0].id,
              amount: variant.variant.attributes.filter((el) => el.name === "amount")[0].pivot.value,
              amountUnitId: variant.variant.attributes.filter((el) => el.name === "amount_unit")[0].id,
              amountUnit: variant.variant.attributes.filter((el) => el.name === "amount_unit")[0].pivot.value,
              patentId: variant.variant.attributes.filter((el) => el.name === "patent")[0].id,
              patent: variant.variant.attributes.filter((el) => el.name === "patent")[0].pivot.value,
            });

            this.attributeForm.attributeData.push({
              unitId: variant.variant.attributes.filter((el) => el.name === "unit")[0].id,
              unit: variant.variant.attributes.filter((el) => el.name === "unit")[0].pivot.value,
              amountId: variant.variant.attributes.filter((el) => el.name === "amount")[0].id,
              amount: variant.variant.attributes.filter((el) => el.name === "amount")[0].pivot.value,
              amountUnitId: variant.variant.attributes.filter((el) => el.name === "amount_unit")[0].id,
              amountUnit: variant.variant.attributes.filter((el) => el.name === "amount_unit")[0].pivot.value,
              patentId: variant.variant.attributes.filter((el) => el.name === "patent")[0].id,
              patent: variant.variant.attributes.filter((el) => el.name === "patent")[0].pivot.value,
            });
          } else {
            // this can happen if there is error when updating (ex. error when hit api)
            // when saving / updating attribute

            this.attributeFormUnmodif.attributeData.push({
              unit: undefined,
              amount: "",
              amountUnit: undefined,
              patent: undefined,
            });

            this.attributeForm.attributeData.push({
              unit: undefined,
              amount: "",
              amountUnit: undefined,
              patent: undefined,
            });
            if (this.formStep2 === false) {
              this.formStep2 = true;
            }
          }

          this.isLoading = false;
          this.formStep0 = false;
          this.formStep1 = false;
          this.buttonSubmitTitle = "Ubah Varian";
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("error fetch variants: ", error);
        });
    },
    fetchDataProduct() {
      // tambah varian
      this.formStep2 = false;
      this.medicineRefData = [];
      const options = {
        productId: this.recordProductId,
      };
      this.fetchProduct(options)
        .then((product) => {
          this.fillAttributes(product.attributes.data);

          this.productDataUnmodif.id = product.product.id;
          this.productDataUnmodif.name = product.product.name;

          this.productData.id = product.product.id;
          this.productData.name = product.product.name;

          product.product.variants.forEach((variant) => {
            const ph = [];
            if (variant.photos.length > 0) {
              ph.push({
                id: variant.photos[0].id,
                variant_photo: variant.photos[0].variant_photo,
              });
            }

            this.variantsFormUnmodif.variantsData.push({
              id: variant.id,
              name: variant.name,
              sku: variant.sku,
              description: variant.description,
              medicine_reference_id: variant.medicine_reference_id,
              medicine_reference_name: variant.medicine_reference_name,
              photos: ph,
              key: variant.id,
            });

            this.variantsForm.variantsData.push({
              id: variant.id,
              name: variant.name,
              sku: variant.sku,
              description: variant.description,
              medicine_reference_id: variant.medicine_reference_id,
              medicine_reference_name: variant.medicine_reference_name,
              photos: ph,
              key: variant.id,
            });

            if (variant.attributes.length > 0) {
              this.attributeFormUnmodif.attributeData.push({
                unitId: variant.attributes.filter((el) => el.name === "unit")[0].id,
                unit: variant.attributes.filter((el) => el.name === "unit")[0].pivot.value,
                amountId: variant.attributes.filter((el) => el.name === "amount")[0].id,
                amount: variant.attributes.filter((el) => el.name === "amount")[0].pivot.value,
                amountUnitId: variant.attributes.filter((el) => el.name === "amount_unit")[0].id,
                amountUnit: variant.attributes.filter((el) => el.name === "amount_unit")[0].pivot
                  .value,
                patentId: variant.attributes.filter((el) => el.name === "patent")[0].id,
                patent: variant.attributes.filter((el) => el.name === "patent")[0].pivot.value,
              });

              this.attributeForm.attributeData.push({
                unitId: variant.attributes.filter((el) => el.name === "unit")[0].id,
                unit: variant.attributes.filter((el) => el.name === "unit")[0].pivot.value,
                amountId: variant.attributes.filter((el) => el.name === "amount")[0].id,
                amount: variant.attributes.filter((el) => el.name === "amount")[0].pivot.value,
                amountUnitId: variant.attributes.filter((el) => el.name === "amount_unit")[0].id,
                amountUnit: variant.attributes.filter((el) => el.name === "amount_unit")[0].pivot
                  .value,
                patentId: variant.attributes.filter((el) => el.name === "patent")[0].id,
                patent: variant.attributes.filter((el) => el.name === "patent")[0].pivot.value,
              });
            } else {
              // this can happen if there is error (ex. error network whle hit api)
              // when saving / updating attribute

              this.attributeFormUnmodif.attributeData.push({
                unit: undefined,
                amount: "",
                amountUnit: undefined,
                patent: undefined,
              });

              this.attributeForm.attributeData.push({
                unit: undefined,
                amount: "",
                amountUnit: undefined,
                patent: undefined,
              });

              if (this.formStep2 === false) {
                this.formStep2 = true;
              }
            }
          });

          this.isLoading = false;
          this.formStep0 = false;
          this.formStep1 = false;
          this.buttonSubmitTitle = "Tambah Varian";
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("error fetch variants: ", error);
        });
    },
  },
  mounted() {
    if (this.id) {
      this.isLoading = true;
      this.fetchDataVariant();
    } else if (this.recordProductId) {
      this.isLoading = true;
      this.fetchDataProduct();
    } else {
      this.variantsForm.variantsData.push({
        name: "",
        sku: "",
        description: "",
        medicine_reference_id: undefined,
        medicine_reference_name: "",
        photos: [],
        key: `${Date.now()}`,
      });

      this.attributeForm.attributeData.push({
        unit: undefined,
        amount: "",
        amountUnit: undefined,
        patent: undefined,
      });

      this.formStep0 = true;
      this.formStep1 = true;
      this.formStep2 = true;

      this.fetchAtributes().then((data) => {
        this.fillAttributes(data.data);
      });
    }
  },
});
</script>

<style lang="scss">
.product-name-title {
  font-size: 32px;
  color: #0146ab;
  font-weight: bold;
  margin-bottom: 12px;
}

.product-steps-container {
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;

  .ant-steps-item-title {
    font-size: 18px;
    font-weight: bold;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #0146ab;
  }

  .ant-steps-item-finish .ant-steps-item-icon  {
    background-color: #0148ab;

    .ant-steps-icon {
      color: #fff;
    }
  }

  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
  }

  .ant-steps .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process).ant-steps-item-wait
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon .ant-steps-icon {
    color: #0148ab;
  }
}

.product-form-container {
  padding-left: 40px;
}

.product-button-container {
  margin-top: 25vh;

  .product-button-cancel {
    width: 47.5%;
    margin-right: 16px;
    border-radius: 26px;
    font-weight: 700;
    color: #0146ab;
  }

  .product-button-next {
    width: 47.5%;
    border-radius: 26px;
    font-weight: 700;
  }
}

.variants-wrapper,
.attributes-wrapper,
.ringkasan-wrapper {
  height: 531px;
  overflow: scroll;
}

.variant-container {
  border: solid 1px #e5e5e6;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
}

.variant-button-container {
  margin-top: 30px;

  .variant-button-prev {
    width: 47.5%;
    margin-right: 16px;
    border-radius: 26px;
    font-weight: 700;
    color: #0146ab;
  }

  .variant-button-next {
    width: 47.5%;
    border-radius: 26px;
    font-weight: 700;
  }
}

.attribute-container,
.ringkasan-container {
  margin-bottom: 16px;

  .p-product-attribute-collapse.ant-collapse,
  .p-product-ringkasan-collapse.ant-collapse {
    border-radius: 16px;

    .ant-collapse-item > .ant-collapse-header {
      background-color: #fff;
      color: #0146ab;
      font-size: 18px;
      font-weight: bold;
    }

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #4c4f54;
    }

    .sub-label {
      font-size: 16px;
      font-weight: 500;
      color: #4c4f54;
    }
  }

  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 16px 16px 16px 16px;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 16px 16px;
  }
}
</style>
