<template>
  <div>
    <div v-show="formStep1">
      <div>
        <span style="font-weight: 700">{{ name }}</span>
        tidak akan ditampilkan lagi pada daftar produk setelah dihapus.
        Apakah Anda ingin melanjutkan?
      </div>
      <div class="delete-variant-button-container">
        <a-button size="large" class="button-cancel" @click="handleCancel">
          Batal
        </a-button>
        <a-button
          size="large"
          type="primary"
          class="button-next"
          @click="changeStep(1)"
        >
          Selanjutnya
        </a-button>
      </div>
    </div>
    <a-form
      :layout="formLayout"
      :form="form"
      @submit="handleSubmit"
      :hideRequiredMark="true"
      v-show="formStep2"
    >
      <div class="margin-baseB" v-if="isSubmitError">
        <a-alert type="error" :message="`Gagal menghapus varian, mohon ulangi`" banner />
      </div>
      <div class="margin-baseB">
        <span>
          Masukkan password Anda untuk menghapus
          <strong>{{ name }}</strong>
          dari daftar produk:
        </span>
      </div>
      <a-form-item
        :validate-status="checkError('password') ? 'error' : ''"
        :help="checkError('password') || ''"
      >
        <a-input-password
          size="large"
          placeholder="Tulis password"
          v-decorator="[
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Data tidak valid',
                  pattern: passwordRegex,
                },
              ],
            },
          ]"
          type="password"
        />
      </a-form-item>
      <a-form-item>
        <div class="p-modal-button-container">
          <a-button
            class="p-modal-cancel"
            size="large"
            @click="handleCancel"
          >
            Batal
          </a-button>
          <a-button
            type="danger"
            size="large"
            html-type="submit"
            :disabled="hasErrors(form.getFieldsError())"
            :loading="loading"
            class="p-modal-submit"
          >
            Hapus
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import Vue from "vue";
import { message } from "ant-design-vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { passwordRegex } from "@/helpers/utils";

export default Vue.extend({
  data() {
    return {
      formLayout: "vertical",
      adminData: {},
      isSubmitError: false,
      passwordRegex,
      formStep1: true,
      formStep2: false,
    };
  },
  props: {
    payload: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("FormStore", {
      hasErrors: "getErrors",
      form: "getForm",
      loading: "getLoading",
    }),
  },
  methods: {
    ...mapMutations("FormStore", {
      setForm: "SET_FORM",
      validateFirst: "VALIDATE_FIRST",
      setLoading: "SET_LOADING",
    }),
    ...mapActions("ProductStore", ["deleteAttributesFromVariant", "deleteVariant", "deleteProducts"]),
    changeStep(next) {
      if (next === 1) {
        this.formStep1 = false;
        this.formStep2 = true;
      }
    },
    checkError(field) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(field) && getFieldError(field);
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.isSubmitError = false;
      this.form.validateFields(() => {
        this.setLoading(true);
        const variantId = this.payload.id;
        const payload = { variantId };
        this.deleteVariant(payload)
          .then(() => {
            // deleting product if this variant is the last variant of the product
            // const payld = [this.payload.product_id];
            // this.deleteProducts(payld)
            //   .catch((error) => {
            //     console.log("error delete product: ", error);
            //   });
            this.setLoading(false);
            message.success(`${this.name} telah berhasil dihapus`);
            this.$emit("handleOk");
          })
          .catch((error) => {
            console.log("error delete variant: ", error);
            this.setLoading(false);
            this.isSubmitError = true;
          });
      });
    },
  },
  created() {
    this.setForm(this.$form.createForm(this, { name: "delete_form" }));
  },
  mounted() {
    this.validateFirst(this);
  },
});
</script>

<style lang="scss" scoped>
.delete-variant-button-container {
  margin-top: 64px;

  .button-cancel {
    width: 47.5%;
    margin-right: 16px;
    border-radius: 26px;
    font-weight: 700;
    color: #0146ab;
  }

  .button-next {
    width: 47.5%;
    border-radius: 26px;
    font-weight: 700;
  }
}
</style>
