<template>
  <div>
    <portal to="header-toolbar">
      <span class="header-download-csv">
        <a-icon type="download" style="margin-right: 8px;" />
        Unduh CSV
      </span>
      <button class="header-button-add" @click="openForm">
        <a-icon type="plus" class="icon" />
        Tambah Produk
      </button>
    </portal>
    <dashboard-table
      searchPlaceholder="Cari Varian"
      :columnTable="columnsVariant"
      :dataTable="getVariantsComputed"
      :pagination="pagination"
      :loadingTable="getUIFlags.isFetching"
      :onSearchTable="onSearch"
      :perPageValue="perPageValue"
      :hideFilter="true"
      @handleTableChange="handleTableChange"
      @showFilter="openFilterModal"
      @handlePerPageChange="handlePerPageChange"
    >
      <template slot="actionDropdown" slot-scope="{ record }">
        <a-menu-item v-if="record.id !== getUserId">
          <a
            href="javascript:;"
            @click="handleAddVariant(record.product_id, record.client_id, record.product_name)"
          >
            Tambah Varian
          </a>
        </a-menu-item>
        <a-menu-item v-if="record.id !== getUserId">
          <a href="javascript:;"
            @click="handleUpdate(record.id, record.client_id, record.name, record.product_name)"
          >
            Ubah Varian
          </a>
        </a-menu-item>
        <template>
          <a-menu-divider />
          <a-menu-item>
            <a href="javascript:;"
              @click="handleDelete(record)"
              class="color-danger"
            >
              Hapus Varian
            </a>
          </a-menu-item>
        </template>
      </template>
    </dashboard-table>
    <product-modal
      :modal-title="modalTitle"
      ref="form"
      @fetchData="fetchData"
      @filter="filterData"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Portal } from "portal-vue";
import { mapActions, mapGetters } from "vuex";
import DashboardTable from "@/components/table/DashboardTable.vue";
import ProductModal from "@/components/product/ProductModal.vue";

const columnsVariant = [
  {
    index: 0,
    title: "No",
    dataIndex: "index",
    key: "index",
    width: 50,
  },
  {
    index: 1,
    title: "Nama Produk",
    dataIndex: "product_name",
    key: "product_name",
    sorter: true,
  },
  {
    index: 2,
    title: "Nama Varian",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    index: 3,
    title: "Referensi Produk",
    dataIndex: "medicine_reference_name",
    key: "medicine_reference_name",
    sorter: false,
  },
  {
    index: 4,
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 100,
    align: "center",
  },
];

export default Vue.extend({
  data() {
    return {
      isFirst: true,
      products: [],
      columnsVariant,
      pagination: {
        page: 1,
        perPage: 10,
        current: 1,
        total: 0,
        pageSize: 0,
      },
      modalTitle: "",
      perPageValue: 10,
      sorter: "",
    };
  },
  components: {
    Portal,
    DashboardTable,
    ProductModal,
  },
  computed: {
    ...mapGetters("ProductStore", ["getVariants", "getUIFlags"]),
    ...mapGetters("UserStore", ["getUserRole", "hasPermission", "getUserId", "getClientId"]),
    getVariantsComputed() {
      const variantData = this.getVariants.map((variant, index) => {
        const tmp = { ...variant };
        tmp.index = this.pagination.pageSize * (this.pagination.current - 1) + index + 1;
        tmp.product_name = tmp.product.name;
        tmp.client_id = tmp.product.client_id;
        return tmp;
      });
      return this.isFirst ? [] : variantData;
    },
  },
  methods: {
    ...mapActions("UserStore", ["getUserData"]),
    ...mapActions("ProductStore", ["fetchVariants", "fetchVariantsByOrder"]),
    openForm() {
      this.modalTitle = "Tambah Produk";
      (this.$refs.form).showModal();
    },
    openFilterModal() {
      this.modalTitle = "Filter";
      (this.$refs.form).showFilter();
    },
    onSearch(value) {
      this.search = value;
      this.pagination.page = 1;
      this.fetchData();
    },
    filterData(filter) {
      this.filter = filter;
      this.pagination.page = 1;
      this.fetchData();
    },
    handleUpdate(recordId, recordClientId, recordVariantName, recordProductName) {
      this.modalTitle = "Ubah Varian";
      (this.$refs.form).showModal(recordId, recordClientId, recordVariantName, recordProductName);
    },
    handleAddVariant(recordProductId, recordClientId, recordProductName) {
      this.modalTitle = "Tambah Varian";
      (this.$refs.form).showAddVariantModal(recordProductId, recordClientId, recordProductName);
    },
    handleDelete(record) {
      this.modalTitle = "Hapus Varian";
      (this.$refs.form).showDelete(record);
    },
    handlePerPageChange(value) {
      this.perPageValue = Number(value);
      const pager = { ...this.pagination };
      pager.perpage = Number(value);
      this.pagination = pager;
      if (pager.perpage > pager.total) {
        this.pagination.page = 1;
      }
      this.fetchData();
    },
    handleTableChange({ pagination, sorter }) {
      if (sorter.order) {
        if (sorter.columnKey === "product_name") {
          this.sorter = [{ column: "PRODUCT_NAME", order: sorter.order === "ascend" ? "ASC" : "DESC" }];
        } else if (sorter.columnKey === "name") {
          this.sorter = [{ column: "NAME", order: sorter.order === "ascend" ? "ASC" : "DESC" }];
        } else {
          this.sorter = "";
        }
      } else this.sorter = "";

      const pager = { ...this.pagination };
      pager.page = pagination.current;
      pager.perpage = pagination.pageSize;
      this.pagination = pager;
      this.pagination.perpage = pagination.pageSize;
      this.pagination.page = pagination.current;
      this.fetchData();
    },
    fetchData() {
      const orderBy = this.sorter ? this.sorter : [{ column: "UPDATED_AT", order: "DESC" }];
      const options = {
        orderBy,
        variantName: this.search || null,
        first: this.pagination.perpage,
        page: this.pagination.page,
      };
      this.fetchVariantsByOrder(options)
        .then((data) => {
          const tmpPagination = { ...this.pagination };
          tmpPagination.total = data.paginatorInfo.total;
          tmpPagination.pageSize = data.paginatorInfo.perPage;
          tmpPagination.current = data.paginatorInfo.currentPage;
          this.pagination = tmpPagination;
          this.isFirst = false;
        })
        .catch((error) => {
          console.log("error fetch variants by order: ", error);
        });
    },
  },
  created() {
    this.$emit("setRightSidebarVisible", false);
  },
  mounted() {
    this.getUserData();
    this.fetchData();
  },
});
</script>

<style lang="scss" scoped>
.header-download-csv {
  margin-right: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #55b9e4;
  cursor: pointer;
}

.header-button-add {
  padding: 14px 24px;
  border-radius: 26px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #0146ab;
  outline: none;

  .icon {
    font-size: 16px;
  }

  &:focus {
    background: #1f64b8;
  }

  &:active {
    background: #0146ab;
  }
}

.prixa-ant-tag {
  padding: 2px 16px;
  border-radius: 16px;
}
</style>
