var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formStep1),expression:"formStep1"}]},[_c('div',[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(_vm.name))]),_vm._v(" tidak akan ditampilkan lagi pada daftar produk setelah dihapus. Apakah Anda ingin melanjutkan? ")]),_c('div',{staticClass:"delete-variant-button-container"},[_c('a-button',{staticClass:"button-cancel",attrs:{"size":"large"},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")]),_c('a-button',{staticClass:"button-next",attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.changeStep(1)}}},[_vm._v(" Selanjutnya ")])],1)]),_c('a-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.formStep2),expression:"formStep2"}],attrs:{"layout":_vm.formLayout,"form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[(_vm.isSubmitError)?_c('div',{staticClass:"margin-baseB"},[_c('a-alert',{attrs:{"type":"error","message":"Gagal menghapus varian, mohon ulangi","banner":""}})],1):_vm._e(),_c('div',{staticClass:"margin-baseB"},[_c('span',[_vm._v(" Masukkan password Anda untuk menghapus "),_c('strong',[_vm._v(_vm._s(_vm.name))]),_vm._v(" dari daftar produk: ")])]),_c('a-form-item',{attrs:{"validate-status":_vm.checkError('password') ? 'error' : '',"help":_vm.checkError('password') || ''}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                required: true,
                message: 'Data tidak valid',
                pattern: _vm.passwordRegex,
              } ],
          } ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Data tidak valid',\n                pattern: passwordRegex,\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","placeholder":"Tulis password","type":"password"}})],1),_c('a-form-item',[_c('div',{staticClass:"p-modal-button-container"},[_c('a-button',{staticClass:"p-modal-cancel",attrs:{"size":"large"},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")]),_c('a-button',{staticClass:"p-modal-submit",attrs:{"type":"danger","size":"large","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()),"loading":_vm.loading}},[_vm._v(" Hapus ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }