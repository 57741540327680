<template>
  <div>
    <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      centered
      :width="widthComputed"
      :footer="null"
      :maskClosable="false"
      :closable="true"
      :destroyOnClose="visibleContent !== 'Filter'"
      :style="{ borderRadius: '24px' }"
      class="p-product-modal"
    >
      <template slot="title">
        <div>
          <span class="p-modal-header-title">
            {{ modalTitle }}
          </span>
        </div>
      </template>
      <template v-if="visibleContent === 'Filter'">
        <admin-filter @filter="handleFilter" @handleCancel="handleCancel" />
      </template>
      <template v-else-if="visibleContent === 'Delete'">
        <delete-variant
          :payload="{
            id: record.id,
            client_id: record.product.client_id,
            attributes: record.attributes,
            product_id: record.product_id,
          }"
          :name="record.name"
          @handleOk="handleOk"
          @handleCancel="handleCancel"
        />
      </template>
      <template v-else-if="visibleContent === ''">
        <div :style="'display: block;'">
          <product-form
            :id="recordId"
            :client-id="recordClientId"
            :variant-name="recordVariantName"
            :record-product-id="recordProductId"
            :record-product-name="recordProductName"
            @handleOk="handleOk"
            @handleCancel="handleCancel"
          />
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from "vue";
import ProductForm from "./ProductForm.vue";
import DeleteVariant from "./DeleteVariant.vue";

export default Vue.extend({
  data() {
    return {
      visible: false,
      confirmLoading: false,
      record: {},
      recordId: "",
      recordClientId: "",
      recordVariantName: "",
      recordProductId: "",
      recordProductName: "",
      visibleContent: "",
    };
  },
  props: {
    modalTitle: {
      type: String,
    },
  },
  components: {
    ProductForm,
    DeleteVariant,
  },
  computed: {
    widthComputed() {
      if (this.visibleContent === "Delete") {
        return 400;
      }

      return 864;
    },
  },
  methods: {
    showModal(id, clientId, recordVariantName, recordProductName) {
      this.visible = true;
      this.visibleContent = "";
      this.recordId = "";
      this.recordClientId = "";
      this.recordVariantName = "";
      this.recordProductId = "";
      this.recordProductName = "";
      if (id && clientId) {
        this.recordId = id;
        this.recordClientId = clientId;
        this.recordVariantName = recordVariantName;
        this.recordProductName = recordProductName;
      }
    },
    showAddVariantModal(recordProductId, clientId, recordProductName) {
      this.visible = true;
      this.visibleContent = "";
      this.recordId = "";
      this.recordClientId = "";
      this.recordVariantName = "";
      this.recordProductId = "";
      this.recordProductName = "";
      if (recordProductId && clientId) {
        this.recordProductId = recordProductId;
        this.recordClientId = clientId;
        this.recordProductName = recordProductName;
      }
    },
    showFilter() {
      this.visible = true;
      this.visibleContent = "Filter";
    },
    showDelete(data) {
      this.visible = true;
      this.visibleContent = "Delete";
      this.record = data;
    },
    handleOk() {
      this.visible = false;
      this.$emit("fetchData");
    },
    handleCancel() {
      this.visible = false;
    },
    handleFilter(params) {
      this.visible = false;
      this.$emit("filter", params);
    },
  },
});
</script>

<style lang="scss">
  .p-product-modal {
    .ant-modal .ant-modal-content .ant-modal-body {
      max-height: 795px;
    }
  }
</style>
